import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { FaCoins, FaTrash, FaShoppingCart, FaSearch } from 'react-icons/fa';
import monteFace from '../assets/voiceshift_logo.png';

const MODEL_COST = 15;

const Shop = ({ user }) => {
  const [userPoints, setUserPoints] = useState(0);
  const [userModels, setUserModels] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredModels, setFilteredModels] = useState([]);

  useEffect(() => {
    if (user) {
      loadUserData();
    }
  }, [user]);

  useEffect(() => {
    const filtered = availableModels.filter(model =>
      model.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredModels(filtered);
  }, [searchTerm, availableModels]);

  const loadUserData = async () => {
    try {
      setLoading(true);
      const [pointsRes, userModelsRes, availableModelsRes] = await Promise.all([
        axios.get(`/api/user/points/${user.id}`),
        axios.get(`/api/models/user/${user.id}`),
        axios.get('/api/models/available')
      ]);

      setUserPoints(pointsRes.data.points);
      setUserModels(userModelsRes.data.map(item => ({
        id: item.param_models.id,
        name: item.param_models.model,
        imageUrl: monteFace
      })));
      
      const availableIds = userModelsRes.data.map(m => m.model_id);
      setAvailableModels(availableModelsRes.data
        .filter(model => !availableIds.includes(model.id) && !model.private)
        .map(model => ({
          id: model.id,
          name: model.model,
          imageUrl: monteFace
        }))
      );
    } catch (error) {
      setError('Failed to load shop data');
      toast.error('Failed to load shop data');
    } finally {
      setLoading(false);
    }
  };

  const buyModel = async (model) => {
    if (userPoints < MODEL_COST) {
      toast.error('Nicht genügend Punkte!');
      return;
    }

    const confirmPurchase = window.confirm(`Dieses Modell kostet ${MODEL_COST} V-Punkte. Möchten Sie fortfahren?`);
    if (!confirmPurchase) return;

    try {
      setLoading(true);
      await axios.post('/api/models/buy', {
        userId: user.id,
        modelId: model.id,
        cost: MODEL_COST
      });

      toast.success('Model erfolgreich gekauft!');
      loadUserData();
    } catch (error) {
      toast.error('Fehler beim Kauf des Modells');
    } finally {
      setLoading(false);
    }
  };

  const removeModel = async (modelId) => {
    const confirmRemove = window.confirm('Wenn du die Stimme entfernst, musst du sie mit 15 Punkten erneut kaufen. Fortfahren?');
    if (!confirmRemove) return;

    try {
      setLoading(true);
      await axios.delete(`/api/models/remove/${user.id}/${modelId}`);
      toast.success('Model erfolgreich entfernt');
      loadUserData();
    } catch (error) {
      toast.error('Fehler beim Entfernen des Modells');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );

  if (error) return (
    <div className="text-center text-red-500 p-4 bg-gray-900">
      <p className="text-xl">{error}</p>
      <button
        onClick={loadUserData}
        className="mt-4 bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors"
      >
        Retry
      </button>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900 p-4 md:p-8">
      <div className="max-w-7xl mx-auto">
        {/* Header mit Suchleiste */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-r from-purple-600 to-blue-500 rounded-xl p-6 mb-8 shadow-2xl"
        >
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <h1 className="text-3xl md:text-4xl font-bold text-white">
              VoiceShift Shop
            </h1>
            <div className="flex items-center space-x-2 bg-gray-800 rounded-full px-4 py-2 shadow-lg border border-gray-700">
              <FaCoins className="text-yellow-400 text-xl" />
              <span className={`font-bold text-xl ${userPoints >= MODEL_COST ? 'text-yellow-400' : 'text-red-400'}`}>
                {userPoints} Punkte
              </span>
            </div>
          </div>
          <div className="mt-4 text-gray-200">
            <p>Jedes Modell kostet {MODEL_COST} Punkte</p>
          </div>
          {/* Suchleiste */}
          <div className="mt-6 relative">
            <div className="relative">
              <input
                type="text"
                placeholder="Suche nach Modellen..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-gray-800/50 backdrop-blur-sm text-black placeholder-gray-600 rounded-lg pl-10 pr-4 py-2 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>
        </motion.div>

        {/* Owned Models */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-white mb-6 border-b border-gray-700 pb-2">
            Ihre Stimmen
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <AnimatePresence>
              {userModels.map((model) => (
                <motion.div
                  key={model.id}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  className="bg-gray-800 rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-all transform hover:scale-105"
                >
                  <div className="p-4">
                    <div className="flex items-center justify-between mb-3">
                      <h3 className="text-lg font-semibold text-white truncate">{model.name}</h3>
                      <img src={model.imageUrl} alt={model.name} className="w-10 h-10 rounded-full" />
                    </div>
                    <button
                      onClick={() => removeModel(model.id)}
                      className="w-full mt-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-3 rounded-lg flex items-center justify-center space-x-1 transition-colors text-sm"
                    >
                      <FaTrash className="text-sm" />
                      <span>Entfernen</span>
                    </button>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>

        {/* Available Models */}
        <div>
          <h2 className="text-2xl font-bold text-white mb-6 border-b border-gray-700 pb-2">
            Verfügbare Stimmen
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <AnimatePresence>
              {filteredModels.map((model) => (
                <motion.div
                  key={model.id}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  className={`bg-gray-800 rounded-xl overflow-hidden shadow-lg transition-all transform hover:scale-105 ${
                    userPoints < MODEL_COST ? 'opacity-75 cursor-not-allowed' : 'hover:shadow-2xl'
                  }`}
                >
                  <div className="p-4">
                    <div className="flex items-center justify-between mb-3">
                      <h3 className="text-lg font-semibold text-white truncate">{model.name}</h3>
                      <img src={model.imageUrl} alt={model.name} className="w-10 h-10 rounded-full" />
                    </div>
                    <div className="flex items-center justify-between mb-3">
                      <span className="text-yellow-400 flex items-center text-sm">
                        <FaCoins className="mr-1" />
                        {MODEL_COST} Punkte
                      </span>
                    </div>
                    <button
                      onClick={() => buyModel(model)}
                      disabled={userPoints < MODEL_COST}
                      className={`w-full font-bold py-2 px-3 rounded-lg flex items-center justify-center space-x-1 transition-all text-sm ${
                        userPoints < MODEL_COST
                          ? 'bg-gray-600 cursor-not-allowed'
                          : 'bg-green-500 hover:bg-green-600'
                      }`}
                    >
                      <FaShoppingCart className="text-sm" />
                      <span>
                        {userPoints < MODEL_COST ? 'Nicht genug Punkte' : 'Kaufen'}
                      </span>
                    </button>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;