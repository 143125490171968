import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import AppRouter from './routing/AppRouter';
import Footer from './components/Footer';
import CookieConsent from "react-cookie-consent";

const App = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth-check`, { withCredentials: true });
        const { isLoggedIn, username } = response.data;
        if (isLoggedIn) {
          setUser({ username });
          setIsLoggedIn(true);

          try {
            const user_response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user`, { withCredentials: true });
            setUser(user_response.data);
          } catch (error) {
            console.error('Error fetching user data:', error);
            setUser(null);
          }
        } else {
          setUser(null);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        setUser(null);
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header user={user} isLoggedIn={isLoggedIn} />
        <div className="flex-grow">
          <AppRouter user={user} isLoggedIn={isLoggedIn} />
        </div>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Ich stimme zu"
          cookieName="userConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.{" "}
        </CookieConsent>
      </div>
    </Router>
  );
};

export default App;
