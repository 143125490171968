import React from 'react';
import { motion } from 'framer-motion';
import mrKrabsDollarSong from '../assets/mr_krabs_dollar.mp3'
import mrKrabs from '../assets/mr_krabs.png'
import microphone from '../assets/microphone.webp'
import tts from '../assets/tts.webp'
import imageCreation from '../assets/image_creation.webp'
import ReactAudioPlayer from 'react-audio-player';
import { FaDiscord, FaRobot, FaMagic, FaMicrophone } from 'react-icons/fa';

const HomePage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-gray-200">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="container mx-auto px-4 sm:px-6 lg:px-8 py-12"
      >
        {/* Hero Section */}
        <motion.header 
          className="text-center mb-16"
          variants={itemVariants}
        >
          <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            VoiceShiftDE
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-gray-300">
            Entdecke die Zukunft der KI-gesteuerten Medien
          </p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-4 px-8 rounded-lg shadow-lg hover:shadow-xl transition duration-300 transform hover:-translate-y-1"
          >
            <a href='https://discord.gg/nzU2e2Asgy' target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
              <FaDiscord className="text-2xl" />
              <span>Jetzt beitreten</span>
            </a>
          </motion.button>
        </motion.header>

        {/* Example Section */}
        <motion.section 
          className="mb-24 text-center"
          variants={itemVariants}
        >
          <h2 className="text-3xl font-bold mb-12 pt-10 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            Beispiel KI Cover
          </h2>
          <motion.div 
            className="flex flex-col items-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="bg-gray-800 p-8 rounded-2xl shadow-2xl max-w-2xl mx-auto">
              <h3 className="text-2xl font-bold mb-6">I NEED A DOLLAR (Mr Krabs Version)</h3>
              <div className="flex flex-col md:flex-row items-center justify-center gap-6">
                <img src={mrKrabs} alt="Mr Krabs" className="w-40 h-40 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300" />
                <div className="w-full max-w-md">
                  <ReactAudioPlayer 
                    src={mrKrabsDollarSong} 
                    controls
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </motion.section>

        {/* Features Section */}
        <motion.section 
          className="mb-24 text-center"
          variants={itemVariants}
        >
          <h2 className="text-4xl font-bold mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
            Unsere Funktionen
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {/* AI Cover Creation */}
            <motion.div 
              className="group bg-gray-800/50 backdrop-blur-lg p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-700/50"
              whileHover={{ y: -10 }}
            >
              <div className="relative mb-6">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-600 rounded-full opacity-75 blur-lg group-hover:opacity-100 transition-opacity"></div>
                <div className="relative bg-gray-900 p-4 rounded-full inline-block">
                  <FaMicrophone className="h-8 w-8 text-blue-400" />
                </div>
              </div>
              <h3 className="text-2xl font-bold mb-4">AI Cover Creation</h3>
              <p className="mb-6 text-gray-400">Erstelle beeindruckende Cover mit unserem KI-basierten Design-Tool.</p>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
              >
                <a href='https://discord.gg/nzU2e2Asgy' target="_blank" rel="noopener noreferrer">
                  Mehr erfahren
                </a>
              </motion.button>
            </motion.div>

            {/* TTS Bot */}
            <motion.div 
              className="group bg-gray-800/50 backdrop-blur-lg p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-700/50"
              whileHover={{ y: -10 }}
            >
              <div className="relative mb-6">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-pink-600 rounded-full opacity-75 blur-lg group-hover:opacity-100 transition-opacity"></div>
                <div className="relative bg-gray-900 p-4 rounded-full inline-block">
                  <FaRobot className="h-8 w-8 text-purple-400" />
                </div>
              </div>
              <h3 className="text-2xl font-bold mb-4">TTS Bot</h3>
              <p className="mb-6 text-gray-400">Verwandle Text in natürliche Sprache mit unserer fortschrittlichen Text-to-Speech-Technologie.</p>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-purple-500 to-pink-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
              >
                <a href='https://discord.gg/nzU2e2Asgy' target="_blank" rel="noopener noreferrer">
                  Mehr erfahren
                </a>
              </motion.button>
            </motion.div>

            {/* AI Image Generation */}
            <motion.div 
              className="group bg-gray-800/50 backdrop-blur-lg p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-700/50"
              whileHover={{ y: -10 }}
            >
              <div className="relative mb-6">
                <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-red-600 rounded-full opacity-75 blur-lg group-hover:opacity-100 transition-opacity"></div>
                <div className="relative bg-gray-900 p-4 rounded-full inline-block">
                  <FaMagic className="h-8 w-8 text-pink-400" />
                </div>
              </div>
              <h3 className="text-2xl font-bold mb-4">AI Image Generation</h3>
              <p className="mb-6 text-gray-400">Generiere einzigartige Bilder und Grafiken mit unserer KI-gesteuerten Bildgenerierung.</p>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-pink-500 to-red-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
              >
                <a href='https://discord.gg/nzU2e2Asgy' target="_blank" rel="noopener noreferrer">
                  Mehr erfahren
                </a>
              </motion.button>
            </motion.div>
          </div>
        </motion.section>
      </motion.div>
    </div>
  );
};

export default HomePage;