import React, { useState, useEffect, useRef } from 'react';
import UserProfile from './UserProfile';
import LogoutButton from './LogoutButton';
import LoginButton from './LoginButton';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ user, isLoggedIn }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Helper function to determine if a link is active
  const isActiveLink = (path) => {
    if (path === '/' && location.pathname === '/') return true;
    if (path !== '/' && location.pathname.startsWith(path)) return true;
    return false;
  };

  return (
    <header className="bg-blue-500 text-white p-4 flex justify-between items-center">
      <Link to="/" className="text-white hover:text-gray-200 no-underline">
        <h1 className="text-2xl font-bold">VoiceShiftDE</h1>
      </Link>
      <div className="flex items-center space-x-4">
        {isLoggedIn ? (
          <div className="relative flex items-center space-x-6" ref={dropdownRef}>
            <nav className="flex items-center space-x-6">
              <Link 
                to="/" 
                className={`text-white hover:text-gray-200 transition-colors ${
                  isActiveLink('/') ? 'font-bold border-b-2 border-white pb-1' : ''
                }`}
              >
                Home
              </Link>
              <Link 
                to="/profile" 
                className={`text-white hover:text-gray-200 transition-colors ${
                  isActiveLink('/profile') ? 'font-bold border-b-2 border-white pb-1' : ''
                }`}
              >
                Profil
              </Link>
              <Link 
                to="/shop" 
                className={`text-white hover:text-gray-200 transition-colors ${
                  isActiveLink('/shop') ? 'font-bold border-b-2 border-white pb-1' : ''
                }`}
              >
                Shop
              </Link>
            </nav>
            <div onClick={toggleDropdown} className="cursor-pointer">
              <UserProfile user={user} />
            </div>
            {dropdownVisible && (
              <div className="dropdown">
                <LogoutButton className="logout-button" />
              </div>
            )}
          </div>
        ) : (
          <LoginButton />
        )}
      </div>
    </header>
  );
};

export default Header;