import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../components/HomePage';
import ProfilePage from '../components/ProfilePage';
import Shop from '../components/Shop';
import ImpressumPage from '../components/Impressum';

// PrivateRoute Komponente
const PrivateRoute = ({ children, isLoggedIn }) => {
  return isLoggedIn ? children : <Navigate to="/" replace />;
};

const AppRouter = ({ user, isLoggedIn }) => {
  return (
    <Routes>
      <Route path="/" element={<HomePage user={user} />} />
      <Route
        path="/profile"
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <ProfilePage user={user} />
          </PrivateRoute>
        }
      />
      <Route
        path="/shop"
        element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <Shop user={user} />
          </PrivateRoute>
        }
      />
      <Route path="/impressum" element={<ImpressumPage />} />
      {/* Catch-all route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRouter;
